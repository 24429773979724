import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly THEME_STORAGE_KEY = 'theme';
  private themeSubject: BehaviorSubject<string>;
  public theme$: Observable<string>;

  constructor() {
    // Recuperar el tema almacenado en LocalStorage o establecer 'dark-theme' como tema predeterminado
    const storedTheme = localStorage.getItem(this.THEME_STORAGE_KEY);
    this.themeSubject = new BehaviorSubject<string>(storedTheme || 'light');
    this.theme$ = this.themeSubject.asObservable(); // Inicializar theme$ después de crear themeSubject
  }

  public toggleTheme(theme: string) {
    this.themeSubject.next(theme);

    // Guardar el tema seleccionado en LocalStorage para mantenerlo entre sesiones
    localStorage.setItem(this.THEME_STORAGE_KEY, theme);
  }
}