import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';

export interface DialogOptions {
  message: string,
  type: Dialogtype,
  isHTML?: boolean
}

export const enum Dialogtype {
  required = 'required',
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  question = 'question',
  samples = 'samples',
  timeAlertSuccess = "timeAlertSuccess",
  timeAlertError = "timeAlertError",
  draftForms = "draftForms"
}
export default Dialogtype;

export abstract class Dialog {


  public static show(message: string, type: Dialogtype, isHTML?: boolean): Observable<any> {

    return from(this.openDialog({ message, type, isHTML }));
  }

  private static openDialog(options: DialogOptions): Promise<any> {

    return new Promise((resolve) => {

      let title = '';

      switch (options.type) {
        case Dialogtype.success:
          title = "¡Éxito!"
          break;
        case Dialogtype.error:
          title = "Error!"
          break;
        case Dialogtype.warning:
          title = "Alerta!"
          break;
        case Dialogtype.required:
          title = "Fields are required!"
          break;
        case Dialogtype.info:
          title = ""
          break;
        case Dialogtype.question:
          title = ""
          break;
        case Dialogtype.samples:
          title = "Succesful"
          break;
        case Dialogtype.samples:
          title = "drillManagent"
          break;
        case Dialogtype.draftForms:
          title = "Succesful"
          break;
      }

      if (options.type == Dialogtype.samples) {
        Swal.fire({
          title: title,
          text: options.message,
          icon: options.type as SweetAlertIcon,
          confirmButtonText: 'Create other form',
          showCancelButton: true,
          cancelButtonText: 'Samples Managent',
          backdrop: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        }).then((result) => {

          if (result.isConfirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }

      if (options.type == Dialogtype.timeAlertSuccess) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Exito',
          text: options.message,
          showConfirmButton: false,
          timer: 2000
        }).then((result) => {

          if (result.isConfirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else if (options.type == Dialogtype.timeAlertError) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error',
          text: options.message,
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {

          if (result.isConfirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }

      else if (options.type == Dialogtype.question) {
        Swal.fire({
          title: title,
          text: options.message,
          icon: options.type as SweetAlertIcon,
          confirmButtonText: 'Aceptar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          backdrop: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        }).then((result) => {

          if (result.isConfirmed) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
      else {

        Swal.fire({
          title: title,
          text: options.message,
          icon: options.type as SweetAlertIcon,
          confirmButtonText: 'Aceptar',
          backdrop: false,
          allowOutsideClick: false,
          html: options.isHTML ? options.message : undefined,
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        }).then(() => {
          resolve(true);
        });
      }

    });

    
  }

}