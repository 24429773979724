import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RightSidebarService } from "./service/rightsidebar.service";
import { AuthGuard } from "./guard/auth.guard";
import { AuthService } from "./service/auth.service";
import { DynamicScriptLoaderService } from "./service/dynamic-script-loader.service";
import { throwIfAlreadyLoaded } from "./guard/module-import.guard";
import { DirectionService } from "./service/direction.service";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { UserService } from "./service/user.service";
import { ShippingQuoteService } from "./service/shipping-quote.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    RightSidebarService,
    AuthGuard,
    AuthService,
    DirectionService,
    ShippingQuoteService,
    DynamicScriptLoaderService,
    UserService
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
