import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },

  ///// MÓDULOS USUARIO /////
  {
    path: "/user/dashboard",
    title: "INICIO",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  },
  {
    path: "/user/my-shippings",
    title: "MIS ENVIOS",
    iconType: "material-icons-two-tone",
    icon: "local_shipping",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  },
  {
    path: "/user/my-clients",
    title: "MIS CLIENTES",
    iconType: "material-icons-two-tone",
    icon: "people",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  },
  /* {
    path: "/user/my-deposits",
    title: "MIS RECARGAS",
    iconType: "material-icons-two-tone",
    icon: "credit_card",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  },
  {
    path: "/user/my-claims",
    title: "NOVEDADES",
    iconType: "material-icons-two-tone",
    icon: "question_answer",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  },
  {
    path: "/user/about",
    title: "ACERCA DE DIPPER",
    iconType: "material-icons-two-tone",
    icon: "info",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["user"],
    submenu: [],
  }, */
  // Common Modules


  ///// MÓDULOS ADMIN /////
  {
    path: "/admin/dashboard",
    title: "INICIO",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },
  {
    path: "/admin/recargas/usuarios",
    title: "RECARGAS",
    iconType: "material-icons-two-tone",
    icon: "wallet",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },
];
