import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Usuario>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(user: Usuario) {
    this.currentUserSubject.next(user);
  }

  login(authData: string):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, authData)
    .pipe(
      map((user) => {
        localStorage.setItem('currentUser', JSON.stringify(user.user));
        this.currentUserSubject.next(user.user);
        return user;
      })
    );
  }

  /* login(authData: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, authData);
  } */

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
