import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShippingQuoteService { 
  private urlBase: string = `${environment.apiUrl}/settlement`;

  constructor(private http: HttpClient) { }

  public cotizarEnvio(data: any): Observable<any>{
    return this.http.post<any>(`${this.urlBase}`, data).pipe(map((res) => {
        return res;
      })
    )
  }

  public generarGuia(data: any): Observable<any>{
    const endpoint = "/guide"
    return this.http.post<any>(`${this.urlBase}${endpoint}`, data).pipe(map((res) => {
        return res;
      })
    )
  }
}