import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Usuario } from '../models/usuario';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url: string = `${environment.apiUrl}/user`;

  constructor(
    private http: HttpClient,
    
  ) { }

  getUsers():Observable<any> {
    return this.http.get<any>(`${this.url}/user-data`)
    .pipe(
      map((user) => {
        return user;
      })
    );
  }

  getUserById(id: string):Observable<any> {
    return this.http.get<any>(`${this.url}/user-data/${id}`)
    .pipe(
      map((user) => {
        return user;
      })
    );
  }

  updateUserById(id: string, body: { numeroCelular: string; }):Observable<any> {
    return this.http.put<any>(`${this.url}/user-data/${id}`, body)
    .pipe(
      map((user) => {
        return user;
      })
    );
  }

  resetPassword(form: { email: string; password: string; }):Observable<any> {
    return this.http.post<any>(`${this.url}/reset-password`, form)
    .pipe(
      map((user) => {
        return user;
      })
    );
  }

  uploadProfilePhoto(formData: FormData):Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/upload/user-data`, formData).pipe(map(res => {
      return res;
    }))
  }
}
